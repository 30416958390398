import {scPage} from "../stockcars-utils-bundle";
import {globalEventEmitter} from "../stockcars-bundle";
import {patternMatching} from 'core-global';

class DealerFormEntry {
	/**
	 * _openFormInNewTab
	 * @param {string} formUrl url to be openend in new tab
	 * @private
	 * @returns {void} void
	 */
	_openFormInNewTab(formUrl = '') {
		// cant use window.open, because without secure user interaction, popup will be blocked
		const a = document.createElement('a');
		a.href = formUrl;
		a.setAttribute('target', '_blank');
		a.click();
	}

	/**
	 * _patchPatternUrl
	 * @param {object} specialLink ctaSpecialLink
	 * @returns {Promise<string>} patchedUrl patchedUrl
	 * @private
	 */
	async _patchPatternUrl(specialLink) {
		const {url = '', pattern = ''} = specialLink;
		let patchedUrl = url;
		if (!!pattern) {
			if (pattern.includes('{{')) {
				patchedUrl = await patternMatching.getReplacedUrl(pattern, url).catch(() => {
					return pattern;
				});
			}
			else {
				patchedUrl = pattern;
			}
		}
		return patchedUrl;
	}

	/**
	 * _getUrlWithVehicleIdParam
	 * @param {string} url url
	 * @returns {string} url url with vehicleId patched
	 * @private
	 */
	_getUrlWithVehicleIdParam(url = '') {
		if (!!url && url.indexOf('vehicleid=') < 0) {
			const vehicleId = scPage.getVehicleIdFromUrl();
			const separatingElement = scPage.getSeparatingElement(url);
			return `${url}${separatingElement}vehicleid=${vehicleId}`;
		}
		else {
			return url;
		}
	}

	/**
	 * handlePost
	 * @private
	 * @returns {void} void
	 */
	handlePost() {
		globalEventEmitter.emit('modalLayer:open', {
			layerType: 'IframePostModalLayerElement'
		});
	}

	/**
	 * handleGet
	 * @param {object} contact contact special link
	 * @returns {Promise<void>} void
	 * @private
	 */
	async handleGet(contact) {
		const {target} = contact;
		const url = await this._patchPatternUrl(contact);
		const urlWithVehicleId = this._getUrlWithVehicleIdParam(url);

		if (target === '_self') {
			scPage.simulateNmLayerLink(urlWithVehicleId);
		}
		else {
			this._openFormInNewTab(urlWithVehicleId);
		}
	}

	/**
	 * handleDealerFormEntry
	 * @param {Map} urlParams urlParams
	 * @returns {Promise<void>} void
	 */
	async handleDealerFormEntry(urlParams) {
		const shouldOpenLayer = urlParams.get('openDealerForm');
		const isDetailPage = scPage.isDetailsPage();

		if (shouldOpenLayer && isDetailPage) {
			const specialLinks = SETUPS.get('stockcar.ctaSpecialLinks');
			const {contact} = specialLinks;

			if (contact) {
				if (contact.method === 'POST') {
					this.handlePost();
				}
				else {
					await this.handleGet(contact);
				}
			}
		}
	}
}

const dealerFormEntry = new DealerFormEntry();
export {dealerFormEntry, DealerFormEntry};

