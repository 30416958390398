(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("core-utils"), require("stockcars-utils-bundle"), require("stockcars-bundle"), require("core-application"), require("stockcars-filter-bundle"), require("core-global"), require("stockcars-vehicle-favorite-bundle"));
	else if(typeof define === 'function' && define.amd)
		define("stockcars-entry-bundle", ["core-utils", "stockcars-utils-bundle", "stockcars-bundle", "core-application", "stockcars-filter-bundle", "core-global", "stockcars-vehicle-favorite-bundle"], factory);
	else if(typeof exports === 'object')
		exports["stockcars-entry-bundle"] = factory(require("core-utils"), require("stockcars-utils-bundle"), require("stockcars-bundle"), require("core-application"), require("stockcars-filter-bundle"), require("core-global"), require("stockcars-vehicle-favorite-bundle"));
	else
		root["stockcars-entry-bundle"] = factory(root["core-utils"], root["stockcars-utils-bundle"], root["stockcars-bundle"], root["core-application"], root["stockcars-filter-bundle"], root["core-global"], root["stockcars-vehicle-favorite-bundle"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__55__) {
return 