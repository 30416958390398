import {scsController, searchAgentEntry} from '../stockcars-bundle';
import {FilterDTO} from '../stockcars-filter-bundle';
import {scPage} from '../stockcars-utils-bundle';
import {favoriteController} from '../stockcars-vehicle-favorite-bundle';
import {ACTIONS, SELECTORS, STCK_STORE} from '@oneaudi/stck-store';
import {appEvents} from 'core-application';
import {dom} from 'core-utils';
import {dealerFormEntry} from "./dealer-form-entry";

class ScEntryController {
	constructor() {
		this._entryHash = window.location.hash;
		this.urlParams = this._getUrlparams();
		this._checkTrackingParamEntry(this.urlParams, window.location);
		this._addSendPostMessageHandler();
		searchAgentEntry.checkReferrer();
		this._checkFilterEntry(window.location.hash);
		favoriteController.checkCurrentFavoritesUpToDate();
		window.onhashchange = this._locationHashChanged.bind(this);
		dealerFormEntry.handleDealerFormEntry(this.urlParams);
	}

	/**
	 * _checkTrackingParamEntry
	 * @param {Map} urlParams urlParams
	 * @param {Object} location location
	 * @private
	 * @returns {void} void
	 */
	_checkTrackingParamEntry(urlParams, location = {}) {
		const csref = urlParams.get('csref');
		const pid = urlParams.get('pid');
		const destinationURL = location.href || '';

		if (csref || pid) {
			STCK_STORE.dispatch(ACTIONS.TRACKING.addTrackingParams({
				csref,
				pid,
				destinationURL
			}));
		}
	}

	/**
	 * _locationHashChanged - add checking on location hash
	 *  change to handle links with same origin and  only filter changes
	 *  @param {string} hash_ location hash
	 * @private
	 * @returns {void} void
	 */
	_locationHashChanged(hash_ = window.location.hash) {
		const hash = (Object.keys(hash_).length && hash_.newURL) ? hash_.newURL : hash_;
		const hashTest = (/[#|&](filter=|preset=)/gi).test(hash);
		if (hashTest) {
			this._checkFilterEntry(hash);
		}
	}

	/**
	 * _addSendPostMessageHandler
	 * @private
	 * @returns {void} void
	 */
	_addSendPostMessageHandler() {
		if (this.urlParams.has('action')) {
			const action = this.urlParams.get('action');
			if (action === 'iframesize') {
				this._sendPostMessage(false);
				this._sendPostMessgeDebounceHandler = dom.debounce(this._sendPostMessage, 100).bind(this);
				document.addEventListener(appEvents.CONTENT_RENDERED, this._sendPostMessgeDebounceHandler);
			}
		}
	}

	/**
	 * _sendPostMessage - gets body dimensions and send with postMessage
	 * @private
	 * @param {Boolean} remove remove
	 * @return {void} void
	 */
	_sendPostMessage(remove = true) {
		const {campaignHeight, campaignUrl} = this._getPostData();
		window.parent.postMessage(JSON.stringify({campaignHeight, campaignUrl}), '*');
		if (remove) {
			document.removeEventListener(appEvents.CONTENT_RENDERED, this._sendPostMessgeDebounceHandler);
		}
	}

	/**
	 * _getPostData
	 * @returns {{clientWidth: *, clientHeight: *}} object with clientWidth and clientHeight
	 * @private
	 */
	_getPostData() {
		const body = document.body;
		const html = document.documentElement;

		const campaignHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
		const campaignUrl = window.location.href;

		return {campaignHeight, campaignUrl};
	}

	/**
	 * _getUrlparams
	 * @param {string} hash hash
	 * @returns {URLSearchParams} URLSearchParams URLSearchParams
	 * @private
	 */
	_getUrlparams(hash = window.location.hash) {
		const modifiedHashString = (hash || '').replace('#', '?');
		return new URLSearchParams(modifiedHashString);
	}

	/**
	 * _setDistanceSorting
	 * @param {Map} actualFilters actualFilters
	 * @param {Map} actualPresets actualPresets
	 * @private
	 * @returns {void} void
	 */
	_setDistanceSorting(actualFilters, actualPresets) {
		const distanceSorting = SETUPS.get('stockcar.scs.distance.sort.param');
		const hacActiveGeoFilter = actualFilters.has('geo');
		const hacActiveGeoPreset = actualPresets.has('geo');

		if (!!distanceSorting && (hacActiveGeoFilter || hacActiveGeoPreset)) {
			STCK_STORE.dispatch(ACTIONS.SORTING.setSorting({sorting: {results: distanceSorting}}));
		}
	}

	/**
	 * _checkAlternativeSortParam
	 * @param {string} hash hash
	 * @static
	 * @private
	 * @returns {void} void
	 */
	_checkAlternativeSortParam(hash = '') {
		if (scPage.isStockcarPage() && !!hash) {
			const useAlternativeSortParam = hash.indexOf('altSort') > -1;

			if (useAlternativeSortParam) {
				const sorting = SETUPS.get('stockcar.scs.alternative.sort.param') || '';
				STCK_STORE.dispatch(ACTIONS.SORTING.setSorting({sorting: {results: sorting}}));
			}
		}
	}

	/**
	 * _setEntrySorting
	 * @param {Map} actualFilters actualFilters
	 * @param {Map} actualPresets actualPresets
	 * @private
	 * @returns {void} void
	 */
	_setEntrySorting(actualFilters, actualPresets) {
		// Set distance sorting if set and active geo filter
		this._setDistanceSorting(actualFilters, actualPresets);
		// Check if url includes altSort ... set sorting with alternative sort param => beats distance sorting
		this._checkAlternativeSortParam(this._entryHash);
	}


	/**
	 * application entry - calls initial scs filter load
	 * @param {string} hash_ location hash
	 * @returns {void} nothing
	 */
	async _checkFilterEntry(hash_ = '') {
		if (scPage.isStockcarPage() && !scPage.isDetailsPage()) {
			const storedFilters = SELECTORS.FILTERS.getPersistedFiltersMaps(STCK_STORE.state);
			const storedPresets = SELECTORS.FILTERS.getPersistedPresetsMap(STCK_STORE.state);
			const parsedObjectsFromHash = ScEntryController.extractFiltersAndPresetsFromHash(hash_);
			let temporaryStoredFilters;
			const keepFilters = new Map([...parsedObjectsFromHash.keepFilters].filter(([key]) => key));
			if (keepFilters.size) {
				temporaryStoredFilters = new Map(storedFilters);
				const temporaryKeys = Array.from(temporaryStoredFilters.keys());
				const filteredKeys = temporaryKeys.filter(key => {
					return Array.from(keepFilters.keys()).some(keepKey => key.startsWith(keepKey));
				});
				const filteredTemporaryStoredFilters = new Map(filteredKeys.map(key => [key, temporaryStoredFilters.get(key)]));
				temporaryStoredFilters.clear();
				filteredTemporaryStoredFilters.forEach((value, key) => temporaryStoredFilters.set(key, value));
			}
			const editablePresets = SETUPS.get('stockcar.editable.presets') ? ScEntryController._parseFilterStringToMap(SETUPS.get('stockcar.editable.presets'), true) : new Map();
			const seoFilterSlugs = ScEntryController.getFiltersFromSeoSlugs(SETUPS.get('scs.seo.slugs') || []);
			if (parsedObjectsFromHash.filters.size) {
				storedFilters.clear();
			}
			// WEBSUPPORT-17398: Global | VTP | SEO feature: SEO URLs do not reset filter
			if (seoFilterSlugs && seoFilterSlugs.size) {
				// do not apply stored filters, because all filters are defined by the seo slugs
				storedFilters.clear();
			}

			this._removePreviousUniquePresets(storedPresets, parsedObjectsFromHash.presets);
			const temporaryFilters = keepFilters.size ? [...temporaryStoredFilters] : [];
			const actualFilters = new Map([...storedFilters, ...parsedObjectsFromHash.filters, ...seoFilterSlugs, ...temporaryFilters]);
			const actualPresets = new Map([...storedPresets, ...parsedObjectsFromHash.presets, ...editablePresets]);

			this._setEntrySorting(actualFilters, actualPresets);

			await scsController.initWithFilters(actualFilters, actualPresets);
			// this._saveSearchAgentStorageState();
		}
	}

	_removePreviousUniquePresets(persistedPresets = new Map(), parsedPresetsFromHash = new Map()) {
		const uniquePresetPatterns = ['geo:'];
		const uniqueConflictingPresetPatterns = ['dealer.', 'dealer-investor-code.', 'dealer-group-name.'];

		uniquePresetPatterns.forEach(uniquePresetPattern => {
			const persistedPreset = [...persistedPresets.keys()].find(preset => preset.includes(uniquePresetPattern));
			const parsedFromHashPreset = [...parsedPresetsFromHash.keys()].find(preset => preset.includes(uniquePresetPattern));
			if (persistedPreset && parsedFromHashPreset) {
				persistedPresets.delete(persistedPreset);
			}
		});

		const containsConflictingKeys = [...parsedPresetsFromHash.keys()].some(preset => uniqueConflictingPresetPatterns.some(key => preset.includes(key)));
		if (containsConflictingKeys) {
			const keys = [...persistedPresets.keys()].filter(preset => uniqueConflictingPresetPatterns.some(key => preset.includes(key)));
			keys.forEach(key => persistedPresets.delete(key));
		}
	}

	/**
	 * save search agent storage state
	 * @returns {void} nothing
	 */
	// ToDo: have to be refactored after referrer story
	// async _saveSearchAgentStorageState() {
	// 	if (searchAgentController.saveSearchStorageState) {
	// 		searchAgentController.saveSearchStorageState = false;
	// 		await searchAgentController.saveSearchAgent();
	// 		searchAgentObserver.triggerUpdate();
	// 	}
	// }

	/**
	 * extract filters and presets from hash
	 * @param {string} hash_ location hash
	 * @returns {object} object with presets and filters map {{filters: Map<any, any>, presets: Map<any, any>}}
	 */
	static extractFiltersAndPresetsFromHash(hash_) {
		let filters = new Map();
		let presets = new Map();
		let keepFilters = new Map();
		if (!!hash_) {
			const decodedHash = decodeURIComponent(hash_);
			const filtersHash = scPage.getFilterHash(decodedHash, 'filter');
			if (!!filtersHash) {
				filters = ScEntryController._parseFilterStringToMap(filtersHash, false);
			}

			const presetsHash = scPage.getFilterHash(decodedHash, 'preset');
			if (!!presetsHash) {
				presets = ScEntryController._parseFilterStringToMap(presetsHash, true);
			}

			const keepFilterHash = scPage.getFilterHash(decodedHash, 'keepFilters');
			if (!!keepFilterHash) {
				keepFilters = ScEntryController._parseFilterStringToMap(keepFilterHash, true);
			}
			// clear current url hash
			scPage.cleanupFilterHash();
		}
		return {filters, presets, keepFilters};
	}

	/**
	 * parse filters (or presets) from a string to a map of FilterDTOs
	 * @param {string} filterString_ - string with comma separated filters
	 * @param {boolean} preset_ flag if presets should be used
	 * @returns {Map<FilterDTO>} a map of filters (or presets)
	 */
	static _parseFilterStringToMap(filterString_, preset_ = false) {
		const currentFiltersMap = new Map();
		const filtersRawArray = filterString_.split(',');
		filtersRawArray.forEach(filter => {
			const filterDTO = FilterDTO.initFromFilterString(filter, true, preset_);
			if (filterDTO) {
				currentFiltersMap.set(filterDTO.id, filterDTO);
			}
		});
		return currentFiltersMap;
	}

	/**
	 * parse filters from an array of seo slugs
	 * @param {array} seoSlugsArray - array with comma filterIds
	 * @returns {Map<FilterDTO>} a map of filters
	 */
	static getFiltersFromSeoSlugs(seoSlugsArray = []) {
		const seoFilterSlugsMap = new Map();
		seoSlugsArray.forEach((filter) => {
			const filterDTO = FilterDTO.initFromFilterString(filter, true);
			if (filterDTO) {
				seoFilterSlugsMap.set(filterDTO.id, filterDTO);
			}
		});
		return seoFilterSlugsMap;
	}
}

const entryController = new ScEntryController();

export {entryController, ScEntryController};
